@font-face {
  font-family: 'SF Pro Display Medium';
  src: local('SF Pro Display Medium'), url(./fonts/SFPRODISPLAYMEDIUM.otf) format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display Bold';
  src: local('SF Pro Display Bold'), url(./fonts/SFPRODISPLAYBOLD.otf) format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF Pro Display'), url(./fonts/SFPRODISPLAYREGULAR.otf) format('opentype'), url(./fonts/FontsFree-Net-SFProDisplay-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text'), url(./fonts/FontsFree-Net-SFProText-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text Bold';
  src: local('SF Pro Text Bold'), url(./fonts/FontsFree-Net-SFProText-Bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'SF Pro Text Medium';
  src: local('SF Pro Text Medium'), url(./fonts/FontsFree-Net-SFProText-Medium.ttf) format('truetype');
  font-display: swap;
}

html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

html::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, 'SF Pro Text', 'SF Pro Display', BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}